import tw from 'twin.macro'
import styled from '@emotion/styled'
import { Link } from 'gatsby'

export const Container = tw.div`max-w-screen-uw uw:max-w-screen-uwu uwu:max-w-screen-uwuu mx-auto`
export const ContainerBoundary = tw.div`uw:max-w-screen-uwu uwu:max-w-screen-uwuu mx-auto`
export const SmallContainer = tw.div`max-w-screen-xl mx-auto`
export const PaddedContainer = tw(Container)`px-6 md:px-6 lg:px-9`

export const SlimContainer = tw.div`max-w-[1438px] mx-auto px-6 md:px-6 lg:px-9`
export const SuperSlimContainer = tw.div`max-w-[1220px] mx-auto px-6 md:px-6 lg:px-9`

// was max-w-screen-3xl. try to use different max-w for different breakpoints. that will work nicely.
export const Content = tw.div`max-w-screen-3xl uw:max-w-screen-uw mx-auto`
export const PaddedContent = tw(Content)`py-9 md:py-16 lg:py-9`

// Buttons
export const AnchorButton = tw.a`inline-block py-4 px-6 xl:py-4 xl:px-12 font-medium text-sm !leading-none transition-colors`
export const Button = tw(
  Link
)`inline-block py-4 px-6 xl:py-4 xl:px-12 font-medium text-sm !leading-none transition-colors`
export const ClickButton = tw.button`inline-block py-4 px-6 xl:py-4 xl:px-12 font-medium text-sm !leading-none transition-colors`
export const MonoButton = tw(
  ClickButton
)`border border-rich-black hover:bg-rich-black hover:text-white bg-rich-black text-white md:bg-transparent md:text-rich-black font-mono font-normal tracking-wide uppercase h-[50px] px-10 xl:px-9`
export const MonoButtonLink = tw(
  Link
)`inline-flex items-center border border-rich-black hover:bg-rich-black hover:text-white bg-rich-black text-white md:bg-transparent md:text-rich-black font-mono font-normal tracking-wide uppercase h-[50px] px-10 xl:px-9 text-sm`
export const DarkMonoButton = tw(
  ClickButton
)`inline-flex items-center border border-rich-black !bg-rich-black !text-white font-mono font-normal tracking-wide uppercase h-[50px] px-10 xl:px-9`
export const DarkMonoButtonLink = tw(
  Link
)`inline-flex items-center border border-rich-black !bg-rich-black !text-white font-mono font-normal tracking-wide uppercase h-[50px] px-10 xl:px-9 text-sm`
export const MonoButtonHeader = tw(
  ClickButton
)`border border-rich-black hover:bg-rich-black hover:text-white bg-rich-black text-white lg:bg-transparent lg:text-rich-black font-mono font-normal tracking-wide uppercase h-[50px] px-10 xl:px-9`
export const MonoButtonInverted = tw(
  ClickButton
)`border border-white text-white hover:bg-white hover:text-rich-black font-mono font-normal tracking-wide uppercase h-[50px] px-10 xl:px-9`
export const SimpleButton = tw(
  Link
)`py-4 px-6 xl:py-4 xl:px-12 text-rich-black font-medium text-sm !leading-none rounded-sm border border-rich-black hover:bg-rich-black hover:text-white transition-colors`
export const SimpleAnchorButton = tw.a`inline-block py-4 px-6 xl:py-4 xl:px-12 text-rich-black font-medium text-sm !leading-none rounded-sm border border-rich-black hover:bg-rich-black hover:text-white transition-colors`
export const BlackButton = tw(
  Link
)`bg-rich-black py-4 px-6 md:py-6 md:px-9 uppercase text-white font-medium text-sm !leading-none rounded-sm transition-colors hover:bg-cultured hover:text-rich-black`
export const SimpleLink = tw(
  Link
)`font-medium text-sm pb-0.5 border-b border-rich-black`
export const LinedButton = styled.button`
  display: inline-block;
  position: relative;
  padding: 16px 0;

  &:after {
    content: '';
    width: 100%;
    border-bottom: solid 1px #0e141a;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 200ms;
  }

  &:hover:after {
    bottom: 12px;
  }
`

// Text
export const PreHeading = tw.span`uppercase text-xs leading-none tracking-wider md:text-sm md:leading-none md:tracking-wider`
export const H3 = tw.h3`font-serif font-semibold text-[20px] md:text-[24px]`
export const Text = tw.p`text-sm md:text-base text-rich-black md:text-slate`

// Links
export const LinedLink = styled(Link)`
  display: inline-block;
  position: relative;
  padding: 16px 0;

  &:after {
    content: '';
    width: 100%;
    border-bottom: solid 1px #0e141a;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 200ms;
  }

  &:hover:after {
    bottom: 12px;
  }
`
